*{
    font-family: "Roboto", sans-serif;
}
#navbar_mx{
    width: 100%;
    height: 65px;
    position: relative;
    box-shadow: 0 2px 2px rgba(97, 97, 97, 0.521);
    z-index: 110;
}
#navbar_mx ul a{color:#000;}
.side-nav li.active{background-color: #1E88E5 !important}
.side-nav li.active > a, nav ul li.active a i.material-icons{color: #FFF;}
.top-navbar{
    width: 100%;
    height: 100%;
    position: relative;
}

/*Matierial Icons */
nav i, nav [class^="mdi-"], nav [class*="mdi-"], nav i.material-icons {
    display:inline !important;
  }
.top-navbar-item{
    min-width: 60px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out 100ms;
}
.top-navbar-item > button{
    background: none;
    outline: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    min-width: 60px;
    height: 65px;
}
.top-navbar-item > button:focus{
    background: none;
    outline: none;
    border: none;
}

/* loader */
#progress-header{
    margin:0;
    display:none;
    background-color: #FFE0B2;
    position: fixed;
  }
#progress-header > .indeterminate{
    background-color: #EF6C00;
  }
.mobile-toggle{
    position: absolute;
    display: none;
    width: 50px;
    height: 50px;
    left: 0;
}
.mobile-toggle > i{
    color: rgb(145, 137, 137);
    font-size: 20px;
    cursor: pointer;
}

/* -------------------- elementos con hover ------------------------ */
.top-navbar-item:hover{
    background: rgba(128, 128, 128, 0.226);
}
.sidebar-route-view:hover, .sidebar-admin-tools:hover{
    background: rgba(128, 128, 128, 0.075);
}
/* -------------------- elementos con active ------------------------ */
.sidebar-route-view.active, .sidebar-admin-tools.active{
    background-color: #1E88E5 !important;
    color: #fff !important;
}
.sidebar-route-view.active >a, .sidebar-admin-tools.active >button{
    color: #fff !important;
}
.sidebar-route-view.active >a >i, .sidebar-admin-tools.active >button > i{
    color: #fff !important;
}

/* ------------------------------------- modal ----------------------------- */
#dialog_nav_search{
    width: 100vw !important;
    height: 100vh !important;
    margin: 0 !important;
    max-width: none !important;
}
#dialog_nav_search > .modal-content{
    width: 100vw !important;
    height: 100vh !important;
    margin: 0 !important;
    max-width: none !important;
    background: #fff;
    padding: 24px;
}
.modal-search-header{
    margin-top: 2rem;
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: end;
    align-items: center;
}
.modal-search-header > i{
    padding: 0 2rem;
    cursor: pointer;
    font-size: 1.3rem;
}
.modal-content-input-search{
    width: 100%;
    height: auto;
    padding: 10px;
}
.input-search-icon{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: green;
}
.input-search{
    width: 100%;
    height: auto;
}
.input-search >label{
    font-size: 12px;
}
.input-search > input{
    background: none !important;
    outline: none !important;
    border: none !important;
    border-bottom: 2px solid green !important;
    box-shadow: none !important;
}
.input-search > input:focus{
    outline: none !important;
}
.advanced-search{
    width: 100%;
    height: auto;
}
.advanced-search >a >i{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #1E88E5;
}
.advanced-search >a{
    font-size: 16px;
    color: #1E88E5;
    text-decoration: none;
}
/* ------------------------------------- sidebar ----------------------------- */
/* cerrar sidebar */
#layer_to_close_sidebar{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    transition: all ease-in-out 200ms;
    top: 0;
    z-index: 9;
}
.side-bar-mx{
    width: 300px;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    box-shadow: 2px 0 0 rgba(119, 119, 119, 0.151);
    z-index: 10;
    top: 0;
    background: #fff;
    transition: all ease-in-out 200ms;
}

.user-session {
    padding: 32px 32px 0;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}
.sidebar-user-img-container{
    width: 100%;
    height: 100%;
    text-align: center;
}
.sidebar-user-img{
    width: 45%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: contain;
    object-position: center;
}
.sidebar-user-name{
    margin-top: 16px;
    font-size: 14px;
    font-weight: 550;
}
.sidebar-user-email{
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
}
.sidebar-route-view{
    transition: all 100ms ease-in-out;
}
.sidebar-route-view > a{
    color: rgba(0,0,0,0.87);
    display: block;
    font-size: 14px;
    font-weight: 500 ;
    height: 48px;
    line-height: 48px;
    padding: 0 32px;
    text-decoration: none;
}
.sidebar-route-view > a >i{
    display: flex;
    justify-content: center;
    float: left;
    height: 48px;
    line-height: 48px;
    margin: 0 32px 0 0;
    width: 24px;
    color: rgba(0,0,0,0.54);
    font-size: 20px;
}

.sidebar-admin-tools > button{
    width: 100%;
    background: none;
    outline: none;
    border: none;
    line-height: inherit;
    height: inherit;
    padding: 0 16px;
    font-weight: 500;
    height: 48px;
    display: flex;
    font-size:14px;
    align-items: center;
}
.sidebar-admin-tools > button >i{
    display: flex;
    justify-content: center;
    float: left;
    height: 48px;
    line-height: 48px;
    margin: 0 32px 0 0;
    width: 24px;
    color: rgba(0,0,0,0.54);
    font-size: 20px;
}

/* --------------------------------- onclicks ------------------------ */
.show-sidebar{
    transform: translateX(0) !important;
}
.not-scroll{
    overflow: hidden !important;
}
/* --------------------------------- media querys ------------------------ */

/* ---------------------- sidebar ------- */
@media (max-width: 992px){
    .side-bar-mx{
        transform: translateX(-300px);
    }
    .content-views{
        padding-left: 0 !important;
    }
    .mobile-toggle{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
    .scrollbox::-webkit-scrollbar{
        width: 7px;
    }
    .scrollbox::-webkit-scrollbar-thumb {
        background-color: #888; /* Color del pulgar de la scrollbar */
        border-radius: 6px; /* Radio del borde del pulgar */
    }
    
    .scrollbox::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color del pulgar cuando se pasa el ratón por encima */
    }
